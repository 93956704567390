<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  created() {
    let a = localStorage.getItem('num')
    if (a) {
      this.$store.commit('increase', {
        num: a,
      })
    }
    let lang = localStorage.getItem('lang')
    if (lang) {
      this.$store.commit('getlang', {
        lang: lang,
      })
      
    } else {
      this.$store.commit('getlang', {
        lang: 'en',
      })
      localStorage.setItem('lang', 'en')
      
    }
   
  },
}
</script>

<style lang="less" scoped>
@import '~assets/css/base.css';
</style>
