import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

// 用来存储数据
const state = {
        num: 0,
        lang: '',
        status: ''
    }
    // 响应组件中的事件
const actions = {

    }
    // 操作数据
const mutations = {
        increase(state, num) {
            console.log(num);
            state.num = num.num
        },
        getlang(state, obj) {

            state.lang = obj.lang
        },
        getstatus(state, obj) {

            state.status = obj.lang
        },

    }
    // 用来将state数据进行加工
const getters = {

    }
    // 新建并暴露store
export default new Vuex.Store({
    state,
    actions,
    mutations,
    getters
})