import Vue from 'vue'
import VueRouter from 'vue-router'
const Login = () =>
    import ( /* webpackChunkName: "login_home_welome" */ 'components/login/Login')
const Home = () =>
    import ( /* webpackChunkName: "login_home_welome" */ 'components/home/Home')
const User = () =>
    import ( /* webpackChunkName: "login_home_welome" */ 'components/home/User/user')
const Index = () =>
    import ( /* webpackChunkName: "login_home_welome" */ 'components/index/index')
const Order = () =>
    import ( /* webpackChunkName: "login_home_welome" */ 'components/order/Order')
const Cart = () =>
    import ( /* webpackChunkName: "login_home_welome" */ 'components/cart/Cart')
const addorder = () =>
    import ( /* webpackChunkName: "login_home_welome" */ 'components/addorder/addorder')



Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: '/home',
    },
    {
        path: '/login',
        component: Login
    },
    {
        path: '/home',
        redirect: '/index',
        component: Home,
        children: [{
                path: '/user',
                component: User,
            },
            {
                path: '/index',
                component: Index,
            },
            {
                path: '/order',
                component: Order,
            },
            {
                path: '/cart',
                component: Cart,
            },
            {
                path: '/addorder',
                component: addorder,
            },

        ]


    }
]
const router = new VueRouter({
    routes
})




router.beforeEach((to, from, next) => {
    // to 将访问哪一个路径
    // from 代表从哪个路径跳转而来
    // next 是一个函数,表示放行
    //   next() 放行 next('/login') 强制跳转
    if (to.path == '/login') return next()
    const token = localStorage.getItem('token')
        // console.log(token)
    if (token == null) return next('login')
    next()
})

export default router